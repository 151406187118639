<template>
  <div class="suspende-questions">
    <div class="questionsTooltip">
      <span class="questionsTtext">悬浮题干</span>
      <span
        class="iconfont icon-fangda enlarge-questions"
        @click="suspendedQuestions()"
      ></span>
    </div>
    <el-popover
      placement="bottom"
      title=""
      trigger="manual"
      content=""
      popper-class="suspendedQuestions-class"
      v-model="suspendedQuestionsClick"
    >
      <div v-drag class="goinvoice">
        <div>
          <span>题干部分</span>
          <span @click="closeQuestions">×</span>
        </div>
        <div
          class="farm-html class-float-questions"
          v-html="getFloatQuestions(stemStatement)"
          style="line-height: 40px"
        ></div>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  data() {
    return {
      suspendedQuestionsClick: false,
    };
  },
  props: {
    stemStatement: {
      default: "",
    },
  },
  methods: {
    getFloatQuestions(html) {
        // let reg = /(\w*)<input type='text' class='fill-blank'(.*)/g;
        let reg = new RegExp("<input type='text' class='fill-blank'","g")
        return html.replace(reg, "<div></div");
    },
    suspendedQuestions() {
      this.suspendedQuestionsClick = true;
    },
    closeQuestions() {
      this.suspendedQuestionsClick = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.suspende-questions {
  margin: auto;
  margin-right: 15px;
}
.questionsTooltip .questionsTtext {
  // visibility: hidden;
  width: 80px;
  font-size: 12px;
  background-color: #e5e5e5;
  color: #666;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  top: -20px;
  right: 1%;
  margin-left: -80px;
}
.questionsTooltip .questionsTtext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 66%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: #e5e5e5 transparent transparent transparent;
}
.questionsTooltip:hover .questionsTtext {
  visibility: visible;
}
.icon-fangda {
  font-size: 2rem;
  padding-left: 2rem;
  cursor: pointer;
}

</style>
<style lang="scss">
.class-float-questions {
  .float-fill-blank {
    padding-left: 4rem;
  }
  table {
    text-align: center;
    margin-bottom: 1rem;
  }
  div {
    // width: 10rem;
    height: 1.6rem;
    display: inline-block;
    // background: rgba(127, 255, 170, 0.2);
    // border-bottom: 1px solid;
    margin: 0px 0.5rem;
  }
  p{
    margin: 0;
    display: inline-block;
  }
}
.suspendedQuestions-class {
  position: fixed;
  width: 40% !important;
  height: 0;
  border: none;
  background: none;
  box-shadow: none;
  top: 19rem;
  right: 50rem;
  padding: 0;
  .goinvoice {
    background-color: #fff;
    box-shadow: 0px 2px 16px 2px rgba(0, 0, 0, 0.25);
    position: relative;
    & > div:nth-child(1) {
      background-color: #2cb774;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 23px;
      & > span:nth-child(2) {
        cursor: pointer;
        width: 4%;
        font-size: 24px;
        display: flex;
        justify-content: flex-end;
      }
    }
    .farm-html {
      padding: 16px;
      max-height: 44rem;
      overflow: auto;
    }
  }
}
</style>