<template>
  <div class="wrapper">
    <div>
      <span class="notes-card">个人笔记</span>
      <span class="iconfont icon-bianji1" @click="edita">
        <span>编辑</span>
      </span>
    </div>
    <div v-show="!isShow" class="note-text">
      {{ comment }}
    </div>
    <div v-show="isShow" style="width: 70%">
      <textarea v-model="comment" style="width: 100%; height: 80px" />
      <div class="btn-group">
        <el-button type="primary" class="save" @click="saveComment"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  GetPrivateNoteForOneQuestion,
  SavePrivateNote,
} from "@/api/english-entry";
import { mapState } from "vuex";
export default {
  name: "personal_notes",
  data() {
    return {
      comment: "",
      id: 0,
      isShow: false,
    };
  },
  computed: {
    ...mapState(["userInfo",]),
    questionId() {
      return this.redoQuestion.questionId;
    },
  },
  created() {
    this.getComment();
  },
  activated() {
    this.getComment();
  },
  methods: {
    edita() {
      this.isShow = true;
    },
    saveComment() {
      this.isShow = false;
      const {
        questionId,
        userInfo: { studentId },
        comment: content,
        id,
      } = this;
      SavePrivateNote({
        questionId,
        studentId,
        content,
        createTime: new Date().toISOString(),
        id,
      });
    },
    getComment() {
      GetPrivateNoteForOneQuestion({ questionId: this.questionId }).then(
        (res) => {
          try {
            const { id, content } = res.data.result[0];
            this.id = id;
            this.comment = content;
          } catch (e) {
            console.log("此题无笔记");
            if (!this.comment) {
              this.isShow = true;
            }
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.note-text {
  padding: 1.5rem 0;
}
.wrapper {
  border-top: 1px solid #e5e5e5;
  text-align: left;
  padding: 22px 15px 0px;
  font-size: 16px;
  /*position: absolute;*/
  /*bottom: 0;*/
  /*left: 0;*/
  width: 100%;
  margin-top: 20px;
  margin-bottom: 22px;
}
.notes-card {
  display: inline-block;
  width: 91px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: #2cb774;
  border-radius: 3px;
  color: $text-white;
  margin-right: 10px;
  & + span {
    color: #666666;
    cursor: pointer;
  }
}
textarea {
  margin-top: 10px;
  background: #ffffff;
  outline: none;
  color: #666666;
  width: 80%;
  height: 104px;
  max-height: 200px;
  // border: 1px dashed #f0f0f0;
  &::placeholder {
    color: #666666;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: $bg-gray;
  }
}
.btn-group {
  height: 55px;
  display: flex;
  justify-content: space-between;
}
.come-back {
  width: 150px;
  height: 45px;
  align-self: flex-end;
}
.save {
  width: 62px;
  height: 31px;
  line-height: 31px;
  background: #fed852;
  border-radius: 0px;
  border-color: #fed852;
}
.el-button {
  padding: 0;
}
.icon-bianji1 {
  span {
    text-decoration: underline;
    padding-left: 0.4rem;
  }
}
.save.is-disabled,
.save.is-disabled:active {
  background-color: #fed852;
  border-color: #fed852;
}
</style>
