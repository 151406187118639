<template>
  <b-row class="result" align-v="center">
    <b-col v-for="(item, index) in groupAnswerStates" :key="index">
      <el-tooltip
        effect="dark"
        placement="top-end"
        :value="disabled[index]"
        :manual="true"
        popper-class="tooltip-popper-item"
      >
        <div slot="content" class="text">{{ text }}</div>
        <div>
          <img class="white-color" :src="imgs[item.colorState]" alt="" />
        </div>
      </el-tooltip>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      imgs: {
        1: require("@/assets/images/icon-zhengque@2x.png"),
        3: require("@/assets/images/icon-cuowu@2x.png"),
        0: require("@/assets/images/icon-kongbai@2x.png"),
      },
      disabled: [false, false, false, false],
      text: "",
    };
  },
  props: {
    groupAnswerStates: {
      default: function () {
        return [];
      },
    },
  },
  computed: {
    ...mapState(["trainingAnswer", ""]),
  },
  methods: {
    point(index) {
      debugger;
      console.log('groupAnswerStates',this.groupAnswerStates)
      this.disabled[index - 1] = true;
      const self = this;
      setTimeout(() => {
        self.$set(self.disabled, index - 1, false);
      }, 5000);
      debugger
      if (this.$route.query.type === "24") {
        if (this.trainingAnswer.questionIsCorrects[0].isCorrect) {
          this.text = "回答正确，真棒！";
        } else {
          this.text = "回答错误，换道题试试吧~";
        }
      } else {
        if (this.trainingAnswer.isCorrect) {
          this.text = "回答正确，真棒！";
        } else {
          this.text = "回答错误，换道题试试吧~";
        }
      }

      // if (sessionStorage.getItem("answerCount") == 2)
      //   return (this.text = "又答错了，换道题试试吧~");

      console.log("提示信息", this.text);
    },
  },
};
</script>

<style lang="scss" scoped>
.white-color {
  width: 25px;
  height: 25px;
}
.result {
  display: flex;
  clear: both;
  position: relative;
  // margin: 30px 0 30px;
  // padding: 0 60px;
  box-sizing: border-box;
  // div {
  //   height: 30px;
  //   margin: 3px;
  // }
  .text {
    padding: 10px;
  }
  img {
    height: 100%;
  }
  .point {
    position: absolute;
    box-sizing: border-box;
    padding: 10px 6px;
    text-align: center;
    top: -80px;
    left: -30px;
    width: 168px;
    height: 70px;
    // background-color: $gray;
    font-size: 18px;
    color: #656565;
    &::before {
      content: "";
      position: absolute;
      width: 0px;
      height: 0;
      border: 10px solid transparent;
      // border-top: 10px solid $gray;
      bottom: -20px;
      right: 40px;
    }
  }
}
</style>
<style lang="scss">
.tooltip-popper-item {
  background-color: #666 !important;
  .popper__arrow::after {
    border-top-color: #666 !important;
  }
}
</style>